.report_usage td {
    padding: 0.75rem !important;
    vertical-align: top !important;
    border: 1px solid #c8ced3 !important;
}
.report_usage th {
    padding: 0.75rem !important;
    vertical-align: top !important;
    border: 1px solid #c8ced3 !important;
}

.filled-row {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: bold;
}

.flex-container {
    display: flex;
}

.content-area {
    margin-bottom: 55px;

    &_subtitle{
        margin-bottom: 2px;
    }
}

// .content-area_subtitle{
//     margin-bottom: 2px;
// }

.inner-table {
    display: table;
    width: 100%;
    margin-bottom: 10px;

    .row {
        display: table-row;

        .cell {
            display: table-cell;
        }

        .cell_title {
            display: table-cell;
            width: 15%;
        }
    }
}

.gantt-chart-table {
    th {
        border-left: 3px solid #c8ced3;
    }

    td {
        padding: 3px 0px !important;
        border-left: 1px solid #c8ced3;
    }
    
    .title {
        padding-left: 15px !important;
    }

    .filled-cell {
        background: blue;
        padding: 12px 0px;
    }
}

.title-page-doc {
    display: none;
    text-align: center;

    .logo {
        background: url('/assets/img/logo.png')
    }
}

.pageBreak {
    page-break-after: "always";
}

.export_container {
    text-align: justify;
}