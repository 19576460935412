.input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .label {
    width: 160px;
    height: 45px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    display: block;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    transition: all 0.18s ease-in-out;
    color: white;
    line-height: 45px;
    background-color: #3f51b5;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.file-link {
  margin: auto 20px;
  font-size: 14px;
  flex: auto;
}

.file-uploader-container {
  flex-wrap: wrap;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
