// Here you can add other styles
body {
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

.ra-rich-text-input {
    display: block !important;
    min-height: 200px;
    border: 1px solid #ccc !important;
    padding: 10px !important;
    margin: 10px 0 !important;
}

.ra-rich-text-input .ql-editor::before {
    display: none !important;
}

.ra-rich-text-input .ql-editor::after {
    background-color: transparent !important;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    .pno-print {
        display: none !important;
    }
}

.common-form-layout {
    width: '100%'
}

.iterator {
    ul {
        width: 100%;
    }
}

.ck.ck-editor__main > .ck-editor__editable {
    height: 150px;
}

.sidebar .nav-dropdown.open .nav-link {
    display: flex !important;
}

.small-list-item {
    display: flex;
    flex-direction: column;
}

.nav-dropdown-items .nav-item .nav-link {
    text-overflow: ellipsis;
    overflow: hidden;
}