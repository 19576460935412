.options {

    &-iterator {
        ul {
            width: 100%;
        }
    }

    &-modules {
        margin-top: 15px;
    }
}

.boolean-selector {
    margin-right: 30px !important;
}