.input-wrapper {
    display: flex; 
    align-items: baseline;
    width: 90%;
    min-width: 250px;
    position: relative;

    &_fillWidth {
        width: 100%;
    }

    &__content {
        width: 100%;

        &_boolean {
            width: initial;
        }

        &_disabled {
            .ra-rich-text-input{
                pointer-events: none;
            }
        }

        label {
            width: 100%;
        }
        label + div {
            width: 100%;
        }
    }

    &__icon {
        margin-left: 15px;
        margin-bottom: 15px;

        &_topRight {
            position: absolute;
            top: 15px;
            right: 0;
        }
    }
}
