.timeline-container {
    padding: 35px 25px;
    position: relative;
}

.timeline-title {
    font-weight: bold;
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.timeline-btn-close {
    position: fixed;
    right: 20px;
    top: 15px;
}

.timeline-content {

}
.timeline-event-description_title {
    margin-bottom: 4px;
}

.icon-wrapper {
    background: #3f51b5;
    border-radius: 50%;
    padding: 10px;
}